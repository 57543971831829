
* {
  margin: 0;
  padding: 0;
  text-align: start;
}

body {
  font-family:'Lato', 'sans-serif';
  overflow-x: hidden;
}

#root {
  width: 100vw;
  max-width: 100vw;
  display: flex;
  justify-content: center;
}


html {
  font-size: 14px;
  box-sizing: border-box;
}

@media (min-width:480px) {
  html {
    font-size: 16px;
  }
}

@media (min-width:1280px) {
  html {
    font-size: 20px;
  }
}

@media (min-width:1920px) {
  html {
    font-size: 24px;
  }
}


@media (min-width:2560px) {
  html {
    font-size: 28px;
  }
}

@media (min-width:3840px) {
  html {
    font-size: 32px;
  }
}
